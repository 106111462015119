.loader {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
  margin-top: 25%;
  background: url(loader.gif) center center no-repeat;
}
.iframe-loader {
  background: url(loader.gif) center center no-repeat;
}
body {
  background: #f1f2f3;
}
